/* eslint-disable camelcase */
import downloadCSV from "@utils/downloadCSV";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

export enum ActionType {
  DISCOUNT = 1,
  EXTEND_DAY = 2,
}

export enum RedeemMethod {
  SINGLE = 1,
  RANDOM = 2,
}

export interface FetchVipRuleListParams {
  titleQ?: string;
  id?: number;
  isActive?: boolean;
  redeemCode?: string;
  startAt?: string;
  endAt?: string;
  limit?: number;
  offset?: number;
}

export interface FetchCreateVipRulePayload {
  title: string;
  redeemMethod: RedeemMethod;
  actionType: ActionType;
  discount: number;
  startAt: string;
  endAt: string;
  codePrefix?: string;
  codeLength?: number;
  maxUseTimes?: number;
  redeemCode?: string;
  isActive: boolean;
}

export interface FetchUpdateVipRulePayload {
  title: string;
  startAt?: string;
  endAt?: string;
  maxUseTimes?: number;
  isActive: boolean;
}

export interface FetchDeleteVipRulePayload {
  ids: number[];
}

export interface FetchDownloadRedeemCodePayload {
  membershipRule: number;
}

export interface VipRule {
  id: number;
  title: string;
  isActive: boolean;
  actionType: ActionType;
  discount: number;
  maxUseTimes: number | null;
  useCount: number;
  redeemCode: string;
  redeemMethod: RedeemMethod;
  useStartAt: string | null;
  useEndAt: string | null;
  codePrefix: string | null;
  codeLength: number | null;
}

interface vipRuleAPI {
  fetchVipRuleList: (
    params: FetchVipRuleListParams,
  ) => Promise<{
    count: number;
    next: string | null;
    previous: string | null;
    results: VipRule[];
  }>;
  fetchVipRule: (vipRuleId: number) => Promise<VipRule>;
  fetchCreateVipRule: (payload: FetchCreateVipRulePayload) => Promise<VipRule>;
  fetchUpdateVipRule: (payload: { payload: FetchUpdateVipRulePayload; vipRuleId: number }) => Promise<void>;
  fetchDeleteVipRule: (payload: FetchDeleteVipRulePayload) => Promise<void>;
  fetchDownloadRedeemCode: (params: FetchDownloadRedeemCodePayload) => Promise<void>;
}

const apiClient = new CoreAPI();

const vipRuleApi: vipRuleAPI = {
  fetchVipRuleList: async (params) => {
    const response = await apiClient.getData("/manage/account/membership/promotion-rules/", {
      title_q: params.titleQ,
      id: params.id,
      is_active: params.isActive,
      redeem_code: params.redeemCode,
      start_at: params.startAt,
      end_at: params.endAt,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipRule: async (vipRuleId: number) => {
    const response = await apiClient.getData(`/manage/account/membership/promotion-rules/${vipRuleId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateVipRule: async (payload) => {
    const response = await apiClient.postData("/manage/account/membership/promotion-rules/", {
      title: payload.title,
      redeem_method: payload.redeemMethod,
      action_type: payload.actionType,
      discount: payload.discount,
      start_at: payload.startAt,
      end_at: payload.endAt,
      code_prefix: payload.codePrefix,
      code_length: payload.codeLength,
      max_use_times: payload.maxUseTimes,
      redeem_code: payload.redeemCode,
      is_active: payload.isActive,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateVipRule: async (payload) => {
    await apiClient.putData(`/manage/account/membership/promotion-rules/${payload.vipRuleId}/`, {
      title: payload.payload.title,
      start_at: payload.payload.startAt,
      end_at: payload.payload.endAt,
      max_use_times: payload.payload.maxUseTimes,
      is_active: payload.payload.isActive,
    });
  },
  fetchDeleteVipRule: async (payload) => {
    await apiClient.putData("/manage/account/membership/promotion-rules/batch-delete/", payload);
  },
  fetchDownloadRedeemCode: async (params) => {
    const response = await apiClient.getData("/manage/account/redeem_codes/csv-download/", {
      membership_rule: params.membershipRule,
    });
    downloadCSV(response);
  },
};

export default vipRuleApi;
